import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { db } from '../firebase';
import { collection, query, where, orderBy, onSnapshot, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import PropTypes from 'prop-types';

const frequencies = ['Quotidien', 'Hebdomadaire', 'Mensuel', 'Annuel'];
const methods = ['Nettoyage à sec', 'Nettoyage humide', 'Désinfection', 'Stérilisation'];

function CleaningPlanOverview({ selectedEnterpriseId }) {
  const [cleaningPlans, setCleaningPlans] = useState([]);
  const [zones, setZones] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const [planToDelete, setPlanToDelete] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);

  // Nouveau : état pour le mode d'affichage dans le modal principal
  const [mode, setMode] = useState('main'); // 'main', 'zone', 'subsection'

  // États pour Zone / Sous-zone
  const [selectedZone, setSelectedZone] = useState('');
  const [newZoneName, setNewZoneName] = useState('');
  const [isAddingZone, setIsAddingZone] = useState(false);

  const [selectedSubsection, setSelectedSubsection] = useState('');
  const [newSubsectionName, setNewSubsectionName] = useState('');
  const [isAddingSubsection, setIsAddingSubsection] = useState(false);
  const [subsections, setSubsections] = useState([]);

  useEffect(() => {
    if (!selectedEnterpriseId) return;

    const q = query(
      collection(db, "cleaningPlans"),
      where("enterpriseId", "==", selectedEnterpriseId),
      orderBy("lastControlDate", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = querySnapshot.docs.map(docSnap => ({
        id: docSnap.id,
        ...docSnap.data(),
        lastControlDate: docSnap.data().lastControlDate?.toDate()
      }));
      setCleaningPlans(items);
      setZones([...new Set(items.map(plan => plan.zone).filter(z => z && z.trim() !== ''))]);
    });

    return () => unsubscribe();
  }, [selectedEnterpriseId]);

  const openModal = (plan) => {
    setCurrentPlan(plan);
    setSelectedZone(plan?.zone || '');
    setSelectedSubsection(plan?.subsection || '');
    if (plan?.zone) loadSubsections(plan.zone);
    setMode('main');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentPlan(null);
    setSelectedZone('');
    setSelectedSubsection('');
    setSubsections([]);
    setNewZoneName('');
    setNewSubsectionName('');
    setIsAddingZone(false);
    setIsAddingSubsection(false);
  };

  const openDeleteConfirm = (plan) => {
    setPlanToDelete(plan);
    setIsDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setPlanToDelete(null);
    setIsDeleteConfirmOpen(false);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const zone = selectedZone;
    if (!zone) {
      alert("Veuillez sélectionner une zone.");
      return;
    }

    const subsection = selectedSubsection || null;

    const data = {
      zone,
      frequency: formData.get('frequency'),
      products: formData.get('products'),
      method: formData.get('method'),
      responsible: formData.get('responsible'),
      lastControlDate: formData.get('lastControlDate') ? new Date(formData.get('lastControlDate')) : null,
      enterpriseId: selectedEnterpriseId
    };

    if (subsection) {
      data.subsection = subsection;
    } else {
      if (currentPlan && currentPlan.subsection && !subsection) {
        data.subsection = null;
      }
    }

    if (currentPlan) {
      await updateDoc(doc(db, "cleaningPlans", currentPlan.id), data);
    } else {
      await addDoc(collection(db, "cleaningPlans"), data);
      if (!zones.includes(zone)) {
        setZones(prev => [...prev, zone]);
      }
    }

    closeModal();
  };

  const handleDelete = async () => {
    if (planToDelete) {
      await deleteDoc(doc(db, "cleaningPlans", planToDelete.id));
      closeDeleteConfirm();
    }
  };

  const loadSubsections = (zone) => {
    if (!zone) {
      setSubsections([]);
      return;
    }
    const filtered = cleaningPlans
      .filter(plan => plan.zone === zone && plan.subsection && plan.subsection.trim() !== '')
      .map(plan => plan.subsection);
    const uniqueSubs = [...new Set(filtered)];
    setSubsections(uniqueSubs);
  };

  // Gestion Zones
  const selectZone = (zone) => {
    setSelectedZone(zone);
    setSelectedSubsection('');
    loadSubsections(zone);
    setMode('main');
  };

  const addNewZone = async () => {
    const trimmed = newZoneName.trim();
    if (trimmed === '') return;
    if (zones.includes(trimmed)) {
      alert('Cette zone existe déjà.');
      return;
    }
    await addDoc(collection(db, "cleaningPlans"), {
      zone: trimmed,
      frequency: '',
      products: '',
      method: '',
      responsible: '',
      lastControlDate: null,
      enterpriseId: selectedEnterpriseId
    });
    setZones(prev => [...prev, trimmed]);
    selectZone(trimmed); // On sélectionne directement la nouvelle zone
  };

  // Gestion Sous-zones
  const selectSubsection = (sub) => {
    setSelectedSubsection(sub);
    setMode('main');
  };

  const addNewSubsection = async () => {
    const trimmed = newSubsectionName.trim();
    if (trimmed === '') return;
    if (subsections.includes(trimmed)) {
      alert('Cette sous-zone existe déjà.');
      return;
    }
    await addDoc(collection(db, "cleaningPlans"), {
      zone: selectedZone,
      subsection: trimmed,
      frequency: '',
      products: '',
      method: '',
      responsible: '',
      lastControlDate: null,
      enterpriseId: selectedEnterpriseId
    });
    setSubsections(prev => [...prev, trimmed]);
    selectSubsection(trimmed);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow-lg rounded-lg p-4 overflow-x-auto">
        <h3 className="text-lg font-semibold text-[#003366] mb-4 text-center">Plans de Nettoyage</h3>
        {zones.map((zone, zoneIndex) => (
          <div key={`zone-${zoneIndex}`} className="mb-6">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h4 className="text-base font-semibold leading-6 text-[#003366]">{zone}</h4>
            </div>
            <table className="min-w-full divide-y divide-gray-200 mb-4">
              <thead>
                <tr>
                  {['Sous-zone', 'Fréquence', 'Produits', 'Méthode', 'Responsable', 'Dernier Contrôle', 'Actions'].map((header, headerIndex) => (
                    <th key={`header-${headerIndex}`} scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cleaningPlans.filter(plan => plan.zone === zone).map((plan) => (
                  <tr key={plan.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.subsection || 'Général'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.frequency}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.products}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.method}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.responsible}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">
                      {plan.lastControlDate ? plan.lastControlDate.toLocaleDateString("fr-FR") : 'Pas encore contrôlé'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">
                      <button onClick={() => openModal(plan)} className="text-[#003366] hover:underline">Modifier</button>
                      <button onClick={() => openDeleteConfirm(plan)} className="text-red-600 hover:underline ml-4">Supprimer</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <div className="mt-4 text-center">
          <button onClick={() => openModal(null)} className="bg-[#003366] hover:bg-[#3399FF] text-white px-4 py-2 rounded-md">Ajouter un plan</button>
        </div>
      </div>

      {/* Modal principal : un seul Dialog, plusieurs modes */}
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300" 
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" 
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300" 
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200" 
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel 
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                >
                  {mode === 'main' && (
                    <div>
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-[#003366]">
                        {currentPlan ? 'Modifier le plan de nettoyage' : 'Ajouter un plan de nettoyage'}
                      </Dialog.Title>
                      <div className="mt-4">
                        <form onSubmit={handleSave} className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Zone</label>
                            <div className="flex items-center space-x-2">
                              {selectedZone ? (
                                <span className="text-gray-700">{selectedZone}</span>
                              ) : (
                                <span className="text-gray-400 italic">Aucune zone sélectionnée</span>
                              )}
                              <button
                                type="button"
                                onClick={() => setMode('zone')}
                                className="px-3 py-1 text-white bg-[#003366] rounded hover:bg-[#3399FF]"
                              >
                                {selectedZone ? 'Modifier' : 'Sélectionner une zone'}
                              </button>
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Sous-zone (optionnel)</label>
                            <div className="flex items-center space-x-2">
                              {selectedSubsection ? (
                                <span className="text-gray-700">{selectedSubsection}</span>
                              ) : (
                                <span className="text-gray-400 italic">Aucune sous-zone sélectionnée</span>
                              )}
                              <button
                                type="button"
                                onClick={() => {
                                  if (!selectedZone) {
                                    alert("Veuillez d'abord sélectionner une zone.");
                                    return;
                                  }
                                  setMode('subsection');
                                }}
                                className="px-3 py-1 text-white bg-[#003366] rounded hover:bg-[#3399FF]"
                              >
                                {selectedSubsection ? 'Modifier' : 'Sélectionner une sous-zone'}
                              </button>
                            </div>
                          </div>

                          <div>
                            <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">Fréquence</label>
                            <select
                              name="frequency"
                              id="frequency"
                              defaultValue={currentPlan?.frequency || ''}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                              required
                            >
                              {frequencies.map((frequency, fIndex) => (
                                <option key={fIndex} value={frequency}>{frequency}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label htmlFor="products" className="block text-sm font-medium text-gray-700">Produits</label>
                            <input type="text" name="products" id="products" defaultValue={currentPlan?.products || ''} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" required />
                          </div>
                          <div>
                            <label htmlFor="method" className="block text-sm font-medium text-gray-700">Méthode</label>
                            <select
                              name="method"
                              id="method"
                              defaultValue={currentPlan?.method || ''}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                              required
                            >
                              {methods.map((method, mIndex) => (
                                <option key={mIndex} value={method}>{method}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label htmlFor="responsible" className="block text-sm font-medium text-gray-700">Responsable</label>
                            <input type="text" name="responsible" id="responsible" defaultValue={currentPlan?.responsible || ''} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" required />
                          </div>
                          <div>
                            <label htmlFor="lastControlDate" className="block text-sm font-medium text-gray-700">Dernier Contrôle</label>
                            <input
                              type="date"
                              name="lastControlDate"
                              id="lastControlDate"
                              defaultValue={currentPlan?.lastControlDate ? currentPlan.lastControlDate.toISOString().split('T')[0] : ''}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            />
                          </div>
                          <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#003366] text-base font-medium text-white hover:bg-[#3399FF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3399FF] sm:ml-3 sm:w-auto sm:text-sm">
                              Enregistrer
                            </button>
                            <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                              onClick={closeModal}
                            >
                              Annuler
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {mode === 'zone' && (
                    <div>
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-[#003366]">
                        Sélectionner une Zone
                      </Dialog.Title>
                      <div className="mt-4">
                        {isAddingZone ? (
                          <div className="space-y-4">
                            <input
                              type="text"
                              placeholder="Nom de la nouvelle zone"
                              value={newZoneName}
                              onChange={(e) => setNewZoneName(e.target.value)}
                              className="w-full border border-gray-300 rounded-md p-2"
                            />
                            <div className="flex justify-end space-x-2">
                              <button
                                type="button"
                                onClick={() => setIsAddingZone(false)}
                                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded text-white"
                              >
                                Annuler
                              </button>
                              <button
                                type="button"
                                onClick={addNewZone}
                                className="px-4 py-2 bg-[#003366] hover:bg-[#3399FF] text-white rounded"
                              >
                                Ajouter
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="space-y-4">
                            {zones.length > 0 ? (
                              <ul className="divide-y divide-gray-200 max-h-48 overflow-auto">
                                {zones.map((zone, index) => (
                                  <li key={index} className="py-2 flex justify-between items-center">
                                    <span className="text-gray-700">{zone}</span>
                                    <button
                                      type="button"
                                      onClick={() => selectZone(zone)}
                                      className="px-3 py-1 bg-[#003366] text-white rounded hover:bg-[#3399FF]"
                                    >
                                      Sélectionner
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <div className="py-2 text-gray-500 italic">Aucune zone existante.</div>
                            )}

                            <div className="text-center">
                              <button
                                type="button"
                                onClick={() => setIsAddingZone(true)}
                                className="px-4 py-2 bg-[#003366] hover:bg-[#3399FF] text-white rounded"
                              >
                                Ajouter une nouvelle zone
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-5 sm:mt-6 flex justify-center">
                        {!isAddingZone && (
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-auto sm:text-sm"
                            onClick={() => setMode('main')}
                          >
                            Retour
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  {mode === 'subsection' && (
                    <div>
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-[#003366]">
                        Sélectionner une Sous-zone
                      </Dialog.Title>
                      <div className="mt-4">
                        {isAddingSubsection ? (
                          <div className="space-y-4">
                            <input
                              type="text"
                              placeholder="Nom de la nouvelle sous-zone"
                              value={newSubsectionName}
                              onChange={(e) => setNewSubsectionName(e.target.value)}
                              className="w-full border border-gray-300 rounded-md p-2"
                            />
                            <div className="flex justify-end space-x-2">
                              <button
                                type="button"
                                onClick={() => setIsAddingSubsection(false)}
                                className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded text-white"
                              >
                                Annuler
                              </button>
                              <button
                                type="button"
                                onClick={addNewSubsection}
                                className="px-4 py-2 bg-[#003366] hover:bg-[#3399FF] text-white rounded"
                              >
                                Ajouter
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="space-y-4">
                            {subsections.length > 0 ? (
                              <ul className="divide-y divide-gray-200 max-h-48 overflow-auto">
                                {subsections.map((sub, subIndex) => (
                                  <li key={subIndex} className="py-2 flex justify-between items-center">
                                    <span className="text-gray-700">{sub}</span>
                                    <button
                                      type="button"
                                      onClick={() => selectSubsection(sub)}
                                      className="px-3 py-1 bg-[#003366] text-white rounded hover:bg-[#3399FF]"
                                    >
                                      Sélectionner
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <div className="py-2 text-gray-500 italic">Aucune sous-zone existante.</div>
                            )}

                            <div className="text-center">
                              <button
                                type="button"
                                onClick={() => setIsAddingSubsection(true)}
                                className="px-4 py-2 bg-[#003366] hover:bg-[#3399FF] text-white rounded"
                              >
                                Ajouter une nouvelle sous-zone
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mt-5 sm:mt-6 flex justify-center">
                        {!isAddingSubsection && (
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-auto sm:text-sm"
                            onClick={() => setMode('main')}
                          >
                            Retour
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Modal de confirmation de suppression */}
      <Transition.Root show={isDeleteConfirmOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDeleteConfirm}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300" 
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" 
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300" 
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200" 
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel 
                  className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
                >
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Confirmer la suppression
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                      Êtes-vous sûr de vouloir supprimer ce plan de nettoyage ? Cette action est irréversible.
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                    <button 
                      type="button" 
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm" 
                      onClick={handleDelete}
                    >
                      Supprimer
                    </button>
                    <button 
                      type="button" 
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm" 
                      onClick={closeDeleteConfirm}
                    >
                      Annuler
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

CleaningPlanOverview.propTypes = {
  selectedEnterpriseId: PropTypes.string.isRequired,
};

export default CleaningPlanOverview;
